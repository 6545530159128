import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatUserData, getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { GET_USER_ME, LIST_ALL_USERS } from './graphql/user';

export interface IAuthContextProps {
  userName: string;
  users: IUserProps[];
  setUserName?(...args: unknown[]): unknown;
  setUserData?(...args: unknown[]): unknown;
  getUserMe: () => Promise<boolean>;
  getAllUsers: () => Promise<Boolean>;
  userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const [userName, setUserName] = useState<string>(
    localStorage.getItem('argos_authUsername') || '',
  );
  const nav = useNavigate();
  const [userData, setUserData] = useState<Partial<IUserProps>>({});
  const [users, setAllUsers] = useState<IUserProps[]>([]);
  async function getUserMe() {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.clear();
      nav('/auth-pages/login');
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'getUserMe',
            query: GET_USER_ME,
          }),
        })
      ).json();
      setUserName(response?.data?.getUserMe.firstname);
      setUserData(formatUserData(response?.data?.getUserMe));
      return true;
    } catch (err) {
      localStorage.clear();
      nav('/auth-pages/login');
      return false;
    }
  }

  async function getAllUsers() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'listAllUsers',
            query: LIST_ALL_USERS,
          }),
        })
      ).json();
      setAllUsers(response?.data?.listAllUsers.map((u: { _id: any }) => formatUserData(u)) || []);
      return true;
    } catch (err) {
      return false;
    }
  }
  useEffect(() => {
    localStorage.setItem('argos_authUsername', userName);
  }, [userName]);

  const value = useMemo(
    () => ({
      userName,
      setUserData,
      setUserName,
      getUserMe,
      getAllUsers,
      userData,
      users,
    }),
    [userName, userData, users],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
