export const LIST_ALL_REPORTINGS = `
    query {
    listAllReportings {
        _id
        createdBy
        createdAt
        updatedAt
        updatedBy
        payedHours
        comentaries {
        _id
        creator
        createdAt
        comment
        }
        streetHours
        missionId
        recruteur
        totalRegistrations
    }
    }
`;

export const CREATE_OR_UPDATE_REPORTING = `
    mutation {
        createOrUpdateReporting($reportingInput: ReportingInput!): {
            createOrUpdateReporting(reportingInput:$reportingInput) {
                _id
                createdBy
                createdAt
                updatedAt
                updatedBy
                payedHours
                comentaries {
                _id
                creator
                createdAt
                comment
                }
                streetHours
                missionId
                recruteur
                totalRegistrations
            }
        }
    }
`;