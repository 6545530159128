export const GET_USER_ME = `
query getUserMe{
	getUserMe{
        email,
        isAdmin,
        name,
        firstname,
        createdBy,
        isActive,
        registrationDate,
        lastLogin,
		uuid,
        phoneNumber
		pp,
    }
}`;

export const LIST_ALL_USERS = `
query listAllUsers{
	listAllUsers{
        _id
		email,
		isAdmin,
		name,
		firstname,
		createdBy,
		isActive
		registrationDate
		lastLogin
		uuid
        status
		pp
        phoneNumber
	}
}
`;

export const LOGIN = `
mutation authUser($email: String!, $password: String!, $origin: String!) {
	authUser (email: $email, password: $password, origin: $origin) {
		token
	}
}
`;
