import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CREATE_OR_UPDATE_REPORTING, LIST_ALL_REPORTINGS } from './graphql/reportings';

export type IComment = {
  _id: string;
  creator: string;
  comment: string;
  createdAt: string;
};
export type IReporting = {
  _id: string;
  missionId: string;
  createdBy: string;
  updatedBy: string;
  totalRegistrations: number;
  createdAt: string;
  updatedAt: string;
  payedHours: number;
  streetHours: string;
  recruteur: string;
  comentaries: [IComment];
};

export interface IReportingContextProps {
  reportings: Partial<IReporting[]>;
  listAllReportings: () => Promise<boolean>;
  createOrUpdateReportings: (reportingInput: IReporting) => Promise<Boolean>;
}
const ReportingContext = createContext<IReportingContextProps>({} as IReportingContextProps);

interface IReportingContextProviderProps {
  children: ReactNode;
}
export const ReportingContextProvider: FC<IReportingContextProviderProps> = ({ children }) => {
  const [reportings, setReportings] = useState<Partial<IReporting[]>>([]);

  async function listAllReportings() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            query: LIST_ALL_REPORTINGS,
          }),
        })
      ).json();
      setReportings(response?.data?.listAllReportings);
      return true;
    } catch (err) {
      return false;
    }
  }

  async function createOrUpdateReportings(reportingInput: IReporting) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'createOrUpdateReporting',
            query: CREATE_OR_UPDATE_REPORTING,
            variables: {
              reportingInput,
            },
          }),
        })
      ).json();
      await listAllReportings();
      return true;
    } catch (err) {
      return false;
    }
  }
  const value = useMemo(
    () => ({
      reportings,
      listAllReportings,
      createOrUpdateReportings,
    }),
    [reportings],
  );
  return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>;
};
ReportingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportingContext;
